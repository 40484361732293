import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Rightcontainer from "../Components/Rightcontainer";
import Accordion from '@mui/material/Accordion';
// import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import Header from "../Components/Header";
import { FaArrowRight } from "react-icons/fa";

import "../css/terms.css";
//import { Interweave } from "interweave";
const All_policy = () => {
    const [data, setData] = useState()
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;

    if (nodeMode === "development") {
        var baseUrl = beckendLocalApiUrl;
    } else {
        var baseUrl = beckendLiveApiUrl
    }

    return (
        <div>
            <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>
                <div   className="mt-5 policy_details_link py-4 px-3">


             
                                    <Link   to="/term-condition"><span className="col giveHover policy_details d-block">Terms & Conditions</span> <FaArrowRight/></Link>
                                    <Link   to="/PrivacyPolicy"><span className="col giveHover policy_details d-block">Privacy policy</span> <FaArrowRight/></Link>
                              
                             
                                    <Link to="/refund-policy"><span className="col giveHover policy_details d-block">Refund/Cancellatioin Policy</span> <FaArrowRight/></Link>

                                    <Link to="/contact-us"> <span className="col giveHover policy_details d-block">Contact Us</span> <FaArrowRight/> </Link>
                              
                               
                                <Link to="/responsible-gaming"> <span className="col giveHover policy_details d-block" >Responsible Gaming </span> <FaArrowRight/></Link>
                                 <Link to="/About"> <span className="col giveHover policy_details d-block">About Us</span> <FaArrowRight/> </Link>
                               
                </div>
            </div>
            <div className="rightContainer">
                <Rightcontainer />
            </div>
        </div>
    );
};
export default All_policy;